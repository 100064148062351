
import { defineComponent, ref, reactive } from "vue";
import { useRoute, onBeforeRouteUpdate } from "vue-router";
import { forEach } from "lodash";
import Chart from "chart.js";
import moment from "moment";
import CalculatorBanner from "@/components/CalculatorBanner.vue";
import { CalculatorFactory } from "@/providers/calculators/calculator-factory";
import { i18n } from "@/i18n";

type Dictionary = {
  [key: string]: any;
};

const { t } = i18n.global;

const parseChartDatasets = (chartData: Dictionary): Dictionary => {
  const datasetKeys: string[] = [
    "P5",
    "P10",
    "P25",
    "P50",
    "P75",
    "P90",
    "P95"
  ];
  const colors: Dictionary = {
    P5: "#115fa6",
    P10: "#94ae0a",
    P25: "#a61120",
    P50: "#ff8809",
    P75: "#ffd13e",
    P90: "#a61187",
    P95: "#24ad9a"
  };

  const datasetConfig = {
    backgroundColor: "transparent",
    pointBackgroundColor: "transparent",
    pointRadius: 0,
    borderWidth: 1
  };

  const labels: Dictionary[] = [];
  const datasets: Dictionary[] = [];

  const markerDataset: Dictionary = {
    label: t("calculators.result"),
    data: [],
    borderColor: "#fff",
    pointBackgroundColor: "#fff",
    pointRadius: 3,
    borderWidth: 0
  };

  forEach(datasetKeys, (item: string, idx: number) => {
    datasets[idx] = {
      label: item.slice(1) + "%",
      data: [],
      borderColor: colors[item],
      ...datasetConfig
    };
  });

  forEach(chartData.values, (item: Dictionary, idx: number) => {
    labels[idx] = item["ageMonths"];
    if (Math.round(chartData.marker.ageMonths) === item["ageMonths"]) {
      markerDataset.data[idx] = chartData.marker.measure;
    } else {
      markerDataset.data[idx] = null;
    }
    forEach(datasetKeys, (datasetKey: any, idxx: number) => {
      datasets[idxx]["data"][idx] = item[datasetKey];
    });
  });

  datasets.unshift(markerDataset);

  return { labels, datasets };
};

const getChartConfig = (chartData: Dictionary): Dictionary => {
  const parsedChartDatasets = parseChartDatasets(chartData);

  const data = {
    labels: parsedChartDatasets.labels,
    datasets: parsedChartDatasets.datasets
  };

  const options = {
    aspectRatio: 1.5,
    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#fff",
            maxRotation: 0,
            autoSkipPadding: 10
          },
          gridLines: {
            color: "rgba(255,255,255,.2)",
            zeroLineColor: "rgba(255,255,255,.2)"
          },
          scaleLabel: {
            display: true,
            labelString: `${t(chartData.axes.y.measure)} (${t(chartData.axes.y.unit)})`,
            fontColor: "#fff",
            fontSize: 11,
            padding: 2
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            fontColor: "#fff",
            maxRotation: 0,
            autoSkipPadding: 10
          },
          gridLines: {
            color: "rgba(255,255,255,.2)",
            zeroLineColor: "rgba(255,255,255,.2)"
          },
          scaleLabel: {
            display: true,
            labelString: `${t(chartData.axes.x.measure)} (${t(chartData.axes.x.unit)})`,
            fontColor: "#fff",
            fontSize: 11,
            padding: 2
          }
        }
      ]
    },
    legend: {
      display: true,
      labels: {
        padding: 4,
        fontSize: 9,
        fontColor: "#fff",
        boxWidth: 4,
        usePointStyle: true
      }
    }
  };

  return { type: "line", data, options };
};

export default defineComponent({
  name: "Product",
  components: {
    "app-calculator-banner": CalculatorBanner
  },
  setup() {
    const route = useRoute();
    const routeSlug = route.params["slug"] as string;

    const result = ref();
    const minDOB = "2000-01-01";
    const maxDOB = ref();
    const minDOM = ref();
    const maxDOM = ref();
    const hasDOB = ref(true);
    const hasDOM = ref(true);
    const hasMeasurementB = ref(false);

    const inputs = reactive({
      ageRange: [],
      dob: "",
      dom: "",
      isMale: true,
      isUnitMetric: true,
      measurementA: 1,
      measurementB: undefined
    });
    const labels = reactive({
      name: "",
      description: "",
      dob: "",
      dom: "",
      measurementAName: "",
      measurementAUnit: "",
      measurementBName: "",
      measurementBUnit: ""
    });

    let calc: any;

    const setDatesRange = () => {
      const dateFormat = "YYYY-MM-DD";
      const dob = inputs.dob;
      const range = inputs.ageRange;
      if (range !== null) {
        maxDOB.value = moment()
          .subtract(1, "days")
          .format(dateFormat);
        minDOM.value = moment(dob, dateFormat)
          .add(range[0], "years")
          .add(1, "day")
          .format(dateFormat);
        maxDOM.value = inputs.dom = moment
          .min(
            moment(),
            moment(dob, dateFormat)
              .add(range[1], "years")
              .subtract(1, "day")
          )
          .format(dateFormat);
      }
    };

    const setCalculator = (slug: string) => {
      const calculator = CalculatorFactory.getWHOCalculator(String(slug));
      calc = new calculator();

      const ipts = calc.getDefaultValues();
      inputs.ageRange = ipts.ageRange;
      inputs.dob = ipts.dob;
      inputs.dom = ipts.dom;
      inputs.isMale = ipts.isMale;
      inputs.isUnitMetric = ipts.isUnitMetric;
      inputs.measurementA = ipts.measurementA;
      inputs.measurementB = ipts.measurementB;

      const lbls = calc.getLabels();
      labels.name = lbls.name;
      labels.description = lbls.description;
      labels.dob = lbls.dob;
      labels.dom = lbls.dom;
      labels.measurementAName = lbls.measurementAName;
      labels.measurementAUnit = lbls.measurementAUnit;
      labels.measurementBName = lbls.measurementBName;
      labels.measurementBUnit = lbls.measurementBUnit;

      hasDOB.value = inputs.dob !== null;
      hasDOM.value = inputs.dob !== null;
      hasMeasurementB.value = inputs.measurementB !== undefined;

      // console.log("ipts:", ipts);
      // console.log("lbls:", lbls);

      setDatesRange();
    };

    const submit = () => {
      const res = calc.calculate(inputs);

      if (res.success) {
        result.value = res.data.percentile;

        // CHART ----
        const chartConfig = getChartConfig(res.data.chart);
        const chartElem = document.getElementById("chart") as HTMLCanvasElement;
        const ctx2d = chartElem.getContext("2d") as CanvasRenderingContext2D;
        new Chart(ctx2d, chartConfig);
        // ----------
      } else {
        result.value = { error: "Error ocurred!" };
      }
    };

    setCalculator(routeSlug);

    onBeforeRouteUpdate((to, from) => {
      if (to.params.slug !== from.params.slug) {
        setCalculator(to.params.slug as string);
      }
    });

    return {
      t,
      labels,
      inputs,
      hasDOB,
      hasDOM,
      hasMeasurementB,
      minDOB,
      maxDOB,
      minDOM,
      maxDOM,
      setDatesRange,
      submit,
      result
    };
  }
});
